$(function () {
    
    $("#toggle-nav").click(function () {
        $(this).toggleClass("active");
        $("#mobile-nav").toggleClass("active");
    });

    $(".mainmenu  .dropdown").hover(function () {
        $('.dropdown-menu', this).stop(true, true).fadeIn("fast");
        $('b', this).toggleClass("caret caret-up");
    },
    function () {
        $('.dropdown-menu', this).stop(true, true).fadeOut("fast");
        $('b', this).toggleClass("caret caret-up");
    });

    function checkRootMenu() {
        if ($(".root-menu").hasClass("active")) {
            $(".menu-header").addClass("active");
        } else {
            $(".menu-header").removeClass("active");
        }
    }
    checkRootMenu();

    function changeMenu(target) {
        var newMenu = $("#" + target);
        $("#back").attr("data-target", $(".menu.active").attr("id"));
        $(".menu.active").removeClass("active");
        $(newMenu).addClass("active");
        checkRootMenu();
    }

    if ($(".menu.active").find("li").length) {
        //What?
    } else {
        changeMenu($("#back").data("target"));
    }

    $(".next, #root").click(function () {
        changeMenu($(this).data("target"));
    });
    $("#back").click(function () {
        changeMenu($(".menu.active").data("parent"));
    });
    $("#newsletterPopupContainer #openButton").click(function (event) {
        event.preventDefault();
        $('#newsletterForm').removeClass("hidden");
        $('#newsletterForm').removeClass("bounceOutDown");
        $('#newsletterForm').addClass("bounceInUp");
    });
    $("#newsletterPopupContainer .fa-close").click(function (event) {
        event.preventDefault();
        $('#newsletterForm').removeClass("thanks bounceInUp");
        $('#newsletterForm').addClass("bounceOutDown");

    });

    $('.show').click(function () {
        $('.newsletter').toggleClass('open');
    });

    ///
    /// OrderSystem
    ///
    $(".userline .toggle-userform").click(function () {
        var button = $(this);
        var email = button.data("email");
        var container = $(this).parent().siblings(".userform");

        if (container.hasClass("hidden")) {
            $.ajax({
                url: "/umbraco/surface/Memberlist/ShowMemberForm?memberEmail=" + email,
                cache: false,
                success: function (data) {
                    button.html('<i class="fa fa-info"></i> Skjul bruger');
                    container.find(".row").html(data);
                }
            });
        } else {
            container.find(".row").empty();
            button.html('<i class="fa fa-info"></i> Vis bruger');
        }

        container.toggleClass("hidden");
    });
    $('.userline').on('click', "button[name ='updateButton']", function (event) {
        $(this).closest("form").find("#Settings_IsApproved").val($(this).val());
    });
    $('.userline').on('submit', 'form.b2b-member-form', function (event) {
        event.preventDefault();

        var formData = $(this).serialize();
        var form = $(this);

        $(this).find("button").attr("disabled", "disabled").html("Gemmer");

        $.ajax({
            type: 'POST',
            url: '/umbraco/surface/Memberlist/UpdateMember',
            cache: false,
            data: formData,
            success: function (data) {
                form.html(data);
            }
        });

        return false;
    });

    //Orderlist
    $(".orderline .toggle-order").click(function () {
        var button = $(this);
        var orderId = button.data("orderid");
        var container = $(this).parent().siblings(".orderform");

        if (container.hasClass("hidden")) {
            $.ajax({
                url: "/umbraco/surface/OrdersList/ShowOrder?orderId=" + orderId,
                cache: false,
                success: function (data) {
                    button.html('<i class="fa fa-info"></i> Skjul bestilling');
                    container.find(".row").html(data);
                },
                error: function (data) {
                    container.find(".row").html("<p class='alert alert-danger'><strong>Der opstod en fejl!</strong> Ordren kunne ikke indl&aelig;ses. Genindl&aelig;s evt. siden og fors&oslash;g igen.</p>");
                }
            });
        } else {
            container.find(".row").empty();
            button.html('<i class="fa fa-info"></i> Se bestilling');
        }
        container.toggleClass("hidden");
    });
    $('.orderline').on('submit', 'form.admin-order-form', function (event) {
        event.preventDefault();

        var formData = $(this).serialize();
        var form = $(this);
        if (confirm(form.find("button[type=submit]").data("message"))) {

            form.find("button").attr("disabled", "disabled").html("Opdaterer...");

            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                cache: false,
                data: formData,
                success: function (data) {
                    form.html(data);
                    if (~form.attr('action').indexOf("DeleteOrder")) {
                        form.closest(".orderline").hide("slow");
                    }
                    if (~form.attr('action').indexOf("UpdateOrder") && ($(form).find('#Processed').val() === 'True')) {
                        $('#process40105').modal('hide');
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        form.closest(".orderline").appendTo(".finished-orders");
                    }
                },
                error: function (data) {
                    form.html("<p class='alert alert-danger'><strong>Der opstod en fejl!</strong> Ordren kunne ikke indl&aelig;ses. Genindl&aelig;s evt. siden og fors&oslash;g igen.</p>");
                }
            });
        }

        return false;
    });

    //Message to customer
    $('.orderline').on('click', ".message-to-customer", function () {
        var order = $(this).data("order");
        var container = $(this).closest(".orderform");
        $.ajax({
            url: "/umbraco/surface/OrderMessage/GetForm?order=" + order,
            cache: false,
            success: function (data) {
                if ($('#' + order).length) {
                    $('#' + order).remove();
                    container.append(data);
                } else {
                    container.append(data);
                }
                $('#' + order).modal('show');
            },
            error: function (data) {
                $(this).html("Der opstod en fejl!");
            }
        });
    });

    $('.orderline').on('submit', 'form.order-message-form', function (event) {
        event.preventDefault();

        var formData = $(this).serialize();
        var form = $(this);
        var button = $(this).find("button[type=submit]");
        button.prop("disabled", true);
        
        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            cache: false,
            data: formData,
            success: function (data) {
                form.find(".modal-body").replaceWith($(data).find('.modal-body'));
                button.prop("disabled", false);
            },
            error: function (data) {
                //form.html("<p class='alert alert-danger'><strong>Der opstod en fejl!</strong> Ordren kunne ikke indl&aelig;ses. Genindl&aelig;s evt. siden og fors&oslash;g igen.</p>");
            }
        });

        return false;
    });


    $('.orderlist-line').on('click', ".toggle-orderlist", function () {
        var button = $(this);
        var listId = button.data("listid");
        var container = $(this).parent().siblings(".orderlist-list");

        if (container.hasClass("hidden")) {
            button.html('<i class="fa fa-info"></i> Skjul liste');

            $.ajax({
                url: "/umbraco/surface/OrderlistList/GetOrderlist?listId=" + listId,
                cache: false,
                success: function (data) {
                    button.html('<i class="fa fa-info"></i> Skjul liste');
                    container.find(".row").html(data);
                },
                error: function (data) {
                    container.find(".row").html("<p class='alert alert-danger'><strong>Der opstod en fejl!</strong> Ordren kunne ikke indl&aelig;ses. Genindl&aelig;s evt. siden og fors&oslash;g igen.</p>");
                }
            });
        } else {
            container.find(".row").empty();
            button.html('<i class="fa fa-info"></i> Vis liste');
        }
        container.toggleClass("hidden");
    });
    $('.orderlist-list').on('click', ".remove-btn", function () {
        var button = $(this);
        var listId = button.data("listid"); 
        var productsku = button.data("productsku");
        var listContainer = button.closest(".orderlist-line");
        button.prop("disabled", true);

        $.ajax({
            type: 'POST',
            url: '/umbraco/surface/OrderlistList/RemoveProduct',
            cache: false,
            data: {
                listId: listId,
                sku: productsku
            },
            success: function (data) {
                button.closest(".product-container").hide("slow");
                var listCount = parseInt(listContainer.data("listcount"));
                listContainer.find(".list-count").text(listCount - 1);
            },
            error: function (data) {
                alert("Der opstod en fejl!");
            }
        });
    });

    $('.orderlist-list').on('keyup submit', "form", function (event) {
        event.preventDefault();
        delay(function () {
            var form = $(event.currentTarget).closest("form");
            
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                cache: false,
                data: form.serialize(),
                success: function (data) {
                    form.siblings(".search-results").html(data);
                },
                error: function (data) {
                    alert("Der opstod en fejl!");
                }
            });
        }, 500);

        return false;
    });

    $('.orderlist-list').on('click', ".add-btn", function () {
        var button = $(this);
        var productSku = button.data("productsku");
        var listContainer = button.closest(".orderlist-line");

        $.ajax({
            type: 'POST',
            url: "/umbraco/surface/OrderlistList/AddProduct",
            cache: false,
            data: {
                listId: listContainer.data("listid"),
                sku: productSku
            },
            success: function (data) {
                var productlist = $(data).filter('.col-md-8.text-left').find(".row").html();
                listContainer = listContainer.find(".orderlist-list").find(".col-md-8.text-left");
                listContainer.fadeOut("fast");
                listContainer.html(productlist).fadeIn("fast");
            },
            error: function (data) {
                alert("Der opstod en fejl!");
            }
        });
    });

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            $("#openButton").addClass("scrollicon");
            $("#newsletterForm").addClass("scrollicon");
        } else {
            $("#openButton").removeClass("scrollicon");
            $("#newsletterForm").addClass("scrollicon");
        }
    });

    if ($("#medlem-maps").length) {
        var map = new GMaps({
            el: '#medlem-map',
            lat: 56.260303,
            lng: 9.2364502,
            zoom: 7
        });

        GMaps.geolocate({
            success: function (position) {
                map.setCenter(position.coords.latitude, position.coords.longitude);
                map.setZoom(9);
            },
            error: function (error) { },
            not_supported: function () {
                alert("Der sketet en fejl da vi skulle finde din placering");
            },
            always: function () { }
        });

        var items = gMarkers['bars'];
        var markers_data = [];
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.lat !== undefined && item.lng !== undefined) {
                markers_data.push({
                    lat: item.lat,
                    lng: item.lng,
                    title: item.name,
                    infoWindow: {
                        content: item.content

                    },
                    icon: {
                        size: new google.maps.Size(32, 32),
                        url: item.icon
                    }
                });
            }
        }
        map.addMarkers(markers_data);
    }

    if ($("#medlem-map").length) {
        initializeMap();
    }

    $('input[type=radio][name=ChosenDeliveryType]').change(function () {
        var chosenDeliveryType = $('input[name=ChosenDeliveryType]:checked', '#orderForm').val();
        if (chosenDeliveryType === "Afhentning") {
            $("#orderForm .hideable").addClass("hidden");
            $("#orderForm .hideable-invert").removeClass("hidden");
        } else {
            $("#orderForm .hideable").removeClass("hidden");
            $("#orderForm .hideable-invert").addClass("hidden");
        }
        
    });

    function initializeMap() {
        var geocoder;
        var map;
        var cluster = [];
        var customerMarker = null;
        cluster = [];
        geocoder = new google.maps.Geocoder();
        var bounds = new google.maps.LatLngBounds();
        var mapOptions = {
            mapTypeId: 'roadmap',
            disableDefaultUI: true,
            scrollwheel: true,
            maxZoom: 16,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
        };

        // Display a map on the page
        map = new google.maps.Map(document.getElementById("medlem-map"), mapOptions);

        // Display multiple markers on a map
        var infoWindow = new google.maps.InfoWindow(), marker, i;
        for (i = 0; i < gMarkers.bars.length; i++) {
            var position = new google.maps.LatLng(gMarkers.bars[i].lat.replace(",", "."), gMarkers.bars[i].lng.replace(",", "."));
            bounds.extend(position);
            marker = new google.maps.Marker({
                position: position,
                icon: '/dist/img/pin.gif',
                map: map,
                title: gMarkers.bars[i].name
            });
            cluster.push(marker);

            //Allow each marker to have an info window
            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    map.setCenter(marker.getPosition());
                    setTimeout(moveMap, 10);
                    function moveMap() {
                        map.panBy(0, -200);
                    }
                    infoWindow.setContent(gMarkers.bars[i].content);
                    infoWindow.open(map, marker);
                };
            })(marker, i));

            // Automatically center the map fitting all markers on the screen
            map.fitBounds(bounds);
        }

        // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
        var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function (event) {
            google.maps.event.removeListener(boundsListener);
        });
    }

    if ($(".frontpage-slider").length) {
        const swiper = new Swiper('.swiper-container', {
            loop: true,
            autoplay: {
                delay: 4000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }
});

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();