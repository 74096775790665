$(function () {
    $("#focalMarker").draggable({
        containment: ".some-edit-wrapper",
        stop: function () {
            setFocalPoint();
        }
    }).resizable({
        containment: ".some-edit-wrapper"
    });

    $("#overlayPicker").change(function () {
        setFocalPoint();
    });
});

function setFocalPoint() {
    var bounds = $(".some-edit-wrapper");
    var position = $("#focalMarker").position();

    var left = position.left / bounds.innerWidth();
    var top = position.top / bounds.innerHeight();

    var editImage = $("#editedImage");
    var url = editImage.data("url");

    var textToRemove = url.substring(url.indexOf("center"), url.indexOf("&"));

    url = url.replace(textToRemove, "");

    var overlay = $("#overlayPicker").val();

    editImage.attr("src", url + "&center=" + top + "," + left + overlay);
}