$(document).ready(function () {
    function search() {
        var s = $(this).val();
        
        $("body").append("<div id='overlay'><i class='fa fa-circle-o-notch fa-spin'></i></div>");
        
        $.ajax({
            data: { term: s },
            url: "/umbraco/surface/Products/GetProducts",
            cache: false
        }).done(function (partialViewResult) {
            $("#searchresults .products").html(partialViewResult);
            $("#searchresults h2").html("Du s&oslash;gte efter: " + s);   
            $("#overlay").remove();
        });
    }

    $("#typeahead input").keyup(
        $.debounce(500, search)
    );

    $(".products").on("click", ".info", function (e) {
        e.preventDefault();
        var prodElement = $(this).closest(".product");
        $("#productinfo .modal-title").html($(prodElement).find(".productname").html());
        $("#productinfo .modal-body").html($(prodElement).find(".description").html());
        $("#productinfo").modal("show");
        return false;
    });

    var page = 0;
    var pagesize = 96;

    $(".products").on("click", ".load-more", function (e) {
        e.preventDefault();
        var totalproducts = parseInt($("#totalproducts").html());
        var totalpages = Math.ceil(totalproducts / pagesize);
        page++;

        var s = $("#typeahead input").val();
        $("body").append("<div id='overlay'><i class='fa fa-circle-o-notch fa-spin'></i></div>");
        
        $.ajax({
            data: { term: s, page: page },
            url: "/umbraco/surface/Products/GetProducts",
            cache: false
        }).done(function (partialViewResult) {
            if (page == totalpages - 1) {
                $(".load-more").remove();
                $("#productsshown").html(totalproducts)
            } else {
                $("#productsshown").html(pagesize * (page + 1))
            }
            
            $("#searchresults .productlist").append(partialViewResult);
            $("#overlay").remove();
        });

        return false;
    });

    function updateOrder() {
        $.ajax({
            url: "/umbraco/surface/Order/UpdateOrder",            
            cache: false
        }).done(function (partialViewResult) {
            var html = $('<div></div>');
            html.html(partialViewResult);
            $("#orderproducts", html).addClass("open");

            $("#cart").html(html.html());
        });
    }

    var order = {};

    function createProduct(sku, name, productType) {
        productType = productType || "";
        var product = {
            sku: sku,
            name: name,
            type: productType,
            //price: price,
            quantity: 1,
            //totalprice: price
        };
        return product;
    }

    $(".products").on("click", ".add", function (e) {
        e.preventDefault();

        var product = {};

        var sku = $(e.currentTarget).data("sku");
        var name = $(e.currentTarget).data("name");
        var type = $(e.currentTarget).data("type");
        //var price = parseFloat($(this).data("price").toString().replace(',', '.'));

        if (Cookies.get("order")) {
            order = JSON.parse(Cookies.get("order"));
        }

        if (order.products !== undefined) {
            var skuExists = order.products.some(function (el) {
                return el.sku == sku;
            });

            if (skuExists) {
                var matches = $.grep(order.products, function (e) {
                    return e.sku == sku;
                });
                matches[0].quantity = matches[0].quantity + 1;
                //matches[0].totalprice = matches[0].totalprice + price;
            } else {
                product = createProduct(sku, name, type);
                order.products.push(product);
            }

            //order.totalprice = order.totalprice + price;
            order.totalquantity = order.totalquantity + 1;
        } else {
            order.products = [];
            product = createProduct(sku, name, type);
            order.products.push(product);
            //order.totalprice = price;
            order.totalquantity = 1;
        }

        Cookies.set("order", JSON.stringify(order), { expires: 365 });

        updateOrder();

        return false;
    });

    function deleteOrderline(sku) {
        //var orderTotalPrice = 0;
        var orderTotalQuantity = 0;

        if (Cookies.get("order")) {
            order = JSON.parse(Cookies.get("order"));
        }

        var matches = $.grep(order.products, function (e) {
            return e.sku != sku;
        });
        order.products = matches;
        
        $.each(order.products, function (index, obj) {
            //orderTotalPrice = orderTotalPrice + (obj.price * obj.quantity);
            orderTotalQuantity = orderTotalQuantity + obj.quantity;
        });

        //order.totalprice = orderTotalPrice;
        order.totalquantity = orderTotalQuantity;

        Cookies.set("order", JSON.stringify(order), { expires: 365 });

        if (order.totalquantity == 0){
            Cookies.remove("order");
        }

        updateOrder();

        return false;
    }
    
    $("#cart").on("click", ".delete", function (e) {
        e.preventDefault();

        var sku = $(this).closest(".row").data("sku");
        deleteOrderline(sku);

        return false;
    });

    $("#cart").on("click", ".add", function (e) {
        e.preventDefault();

        var order = {};
        var sku = $(e.currentTarget).closest(".row").data("sku");
        
        if (Cookies.get("order")) {
            order = JSON.parse(Cookies.get("order"));
        }

        var totalquantity = 0;
        var updatedProds = [];
        updatedProds = $.grep(order.products, function (e) {
            if (typeof e.sku !== "undefined") {
                totalquantity = totalquantity+e.quantity;
                return e;
            }
        });
        order.totalquantity = totalquantity;
        order.products = updatedProds;

        var matches = $.grep(order.products, function (e) {
            return e.sku == sku;
        });

        if (typeof matches[0] === "undefined") {
            Cookies.set("order", JSON.stringify(order), { expires: 365 });
            updateOrder();
            return false;
        }

        matches[0].quantity = matches[0].quantity + 1;
        //matches[0].totalprice = matches[0].totalprice + matches[0].price;
        //order.totalprice = order.totalprice + matches[0].price;
        order.totalquantity = order.totalquantity + 1;
        
        Cookies.set("order", JSON.stringify(order), { expires: 365 });

        updateOrder();

        return false;
    });

    $("#cart").on("click", ".subtract", function (e) {
        e.preventDefault();

        var sku = $(e.currentTarget).closest(".row").data("sku");

        if (Cookies.get("order")) {
            order = JSON.parse(Cookies.get("order"));
        }

        var totalquantity = 0;
        var updatedProds = [];
        updatedProds = $.grep(order.products, function (e) {
            if (typeof e.sku !== "undefined") {
                totalquantity = totalquantity + e.quantity;
                return e;
            }
        });
        order.totalquantity = totalquantity;
        order.products = updatedProds;

        var matches = $.grep(order.products, function (e) {
            return e.sku == sku;
        });

        if (typeof matches[0] === "undefined") {
            Cookies.set("order", JSON.stringify(order), { expires: 365 });
            updateOrder();
            return false;
        }

        if (matches[0].quantity == 1) {
            deleteOrderline(sku);
        } else {
            matches[0].quantity = matches[0].quantity - 1;
            //matches[0].totalprice = matches[0].totalprice - matches[0].price;
            //order.totalprice = order.totalprice - matches[0].price;
            order.totalquantity = order.totalquantity - 1;

            Cookies.set("order", JSON.stringify(order), { expires: 365 });

            updateOrder();
        }        

        return false;
    });

    $("#cart").on("click", ".open-order", function (e) {
        e.preventDefault();
        $("#orderproducts").addClass("open");
        return false;
    });

    $(".open-form").on("click", function (e) {
        e.preventDefault();
        $("#orderproducts").removeClass("open");
        $("#orderform").addClass("open");
        return false;
    });

    $("#cart").on("click", ".open-form", function (e) {
        e.preventDefault();
        $("#orderproducts").removeClass("open");
        $("#orderform").addClass("open");
        return false;
    });

    $("#cart").on("click", ".close", function (e) {
        e.preventDefault();
        $("#orderproducts").removeClass("open");
        return false;
    });

    $("#order").on("click", ".open-order", function (e) {
        e.preventDefault();
        $("#orderform").removeClass("open");
        $("#orderproducts").addClass("open");
        return false;
    });

    $("#order").on("click", ".close", function (e) {
        e.preventDefault();
        $("#orderform").removeClass("open");
        return false;
    });

    $("#order").on("change", "input[type=radio][name=deliverytype]", function (e) {
        e.preventDefault();
        if (this.value == "Afhentning") {
            $("#pickuppoints").show();
        } else {
            $("#pickuppoints").hide();
        }
        
        return false;
    });

    $("#altemail").val($("#pickuppoints option:selected").data("email"));

    $("#order").on("change", "#pickuppoints", function (e) {
        e.preventDefault();

        console.log($("#pickuppoints option:selected").data("email"));

        $("#altemail").val($("#pickuppoints option:selected").data("email"));

        return false;
    });

    $("#customerinfo").validate();

    $("#order").on("click", ".send-order", function (e) {
        e.preventDefault();

        if ($("#customerinfo").valid()) {

            $("body").append("<div id='overlay'><i class='fa fa-circle-o-notch fa-spin'></i></div>");

            $.ajax({
                url: "/umbraco/surface/OrderForm/Submit",
                method: "POST",
                cache: false,
                data: $('#customerinfo').serialize()
            }).done(function (success) {
                var html = "<div id='orderform' class='container open'>";
                html = html + "<h2>Tak for din bestilling</h2>";
                html = html + "<button type='button' class='close'><i class='fa fa-close'></i></button>";
                html = html + "<div class='alert alert-success'>Tak for din bestilling - Du vil modtage en kvittering p&aring; mail n&aring;r apoteket har modtaget ordren.</div>";
                html = html + "</div>";

                Cookies.remove("order");
                $("#cart").html("");
                $("#order").html(html);
                $("#overlay").remove();

            });
        }
        return false;
    });

    $(".orderline").on("click", ".reorder", function (e) {
        e.preventDefault();

        var orderJson = $(e.currentTarget).data("json");

        Cookies.set("order", JSON.stringify(orderJson), { expires: 365 });
        updateOrder();
        
        return false;
    });
});